/**
 * ADAS报警解析
 */


export const resolveMedia = value => {
  
  let descMedias = [
    ['isAudio', '0x01', 1, '音频'],
    ['isVideo', '0x02', 2, '视频'],
    ['isAV', '0x03', 0, '音视频'],
    ['isPicture', '0x04', 5, '图片'],
    ['isLocate', '0x08', -1, '定位']
  ];
  let media = null;
  descMedias.forEach(p => {
    let flag = Number(value) & Number(p[1]) ? true : false;
    if (flag) {
      media = media || {};
      media[p[0]] = flag;
      media.value = p[2];
      media.desc = p[3];
    }
  });
  return media;
};


export const resolveAlarmSign = driveType => {
  // driveType = [1,2,1]
  let descDriverBehavior = [
    ['is' + 'Fatigue', '0x' + 1, '疲劳', 0],
    ['is' + 'Phone', '0x' + 2, '打电话', 0],
    ['is' + 'Smoking', '0x' + 4, '吸烟', 0],
    ['is' + 'Robber', '0x' + 8, '劫警', 0],
    ['is' + 'FCW', '0x' + 200, 'FCW前车碰撞预警', 0],
    ['is' + 'LDW', '0x' + 400, '道路偏离', 0],
    // ['is' + 'PCW', '0x' + 800, 'PCW行人检测预警', 0],
    ['is' + 'Deviate', '0x' + 1000, '镜头遮挡', 0],
    ['is' + 'ClosedEye', '0x' + 2000, '闭眼', 0],
    ['is' + 'Yawn', '0x' + 4000, '打哈欠', 0],
    ['is' + 'AbnormalPosture', '0x' + 8000, '姿态异常', 0],
    // ['is' + 'NotWearEeatbelt', '0x' + 1, '不系安全带', 2],
    // ['is' + 'EquipmentFailure', '0x' + 2, 'ADAS设备故障', 2],
    ['is' + 'FrontCarNear', '0x' + 4, '前车近距', 2],
    // ['is' + 'Braking', '0x' + 8, '急刹车', 2],
    // ['is' + 'Departure', '0x' + 10, '离岗', 2],
    // ['is' + 'Accelerate', '0x' + 20, '急加速', 2],
    // ['is' + 'RapidDeceleration', '0x' + 40, '急减速', 2],
    // ['is' + 'AcuteLeftTurn', '0x' + 80, '急左转弯', 2],
    // ['is' + 'AcuteRightTurn', '0x' + 100, '急右转弯', 2],
    // ['is' + 'Bow', '0x' + 200, '低头', 2],
    // ['is' + 'LaneChange', '0x' + 400, '变道', 2],
    // ['is' + 'SignTransfinite', '0x' + 800, '道路标识超限', 2],
    // ['is' + 'Obstacle', '0x' + 1000, '障碍物', 2],
    // ['is' + 'Distinguish', '0x' + 2000, '道路标志识别', 2],
    // ['is' + 'Snap', '0x' + 4000, '抓拍', 2],
    // ['is' + 'Distraction', '0x' + 4, '分神', 2],
    ['is' + 'DriverEx', '0x' + 8000, '驾驶员异常', 2],
    // ['is' + 'DsmSnap', '0x' + 10000, '主动抓拍', 2],
    // ['is' + 'ReplaceDriver', '0x' + 20000, '驾驶员替换', 2]
  ];
  let driverBehavior = {
    desc: '',
    driveType
  };
  driverBehavior.descs = descDriverBehavior.reduce((a, b) => {
    driverBehavior[b[0]] = !!(Number(Math.abs(driveType[b[3]])) & Number(b[1]));
    if (driverBehavior[b[0]]) {
      a.push(b[2]);
    }
    return a;
  }, []);
  // descDriverBehavior.forEach(p => {
  //   driverBehavior[p[0]] = Number(driveType[p[3]]) & Number(p[1]) ? true : false
  //   if (driverBehavior[p[0]]) {
  //     driverBehavior.desc += p[2] + ';'
  //   }
  // });
  // if (driverBehavior.desc.length <= 0) driverBehavior.desc = '无'
  return driverBehavior;
};
