<template>
  <div class="track">
    <div class="track-head">
      <div class="track-head-item">
        <div
          class="track-head-refresh"
          style="margin-right: 16px"
          @click="getList()"
        >
          <img
            src="../../../assets/images/realTimePositioning/map-sx.png"
            alt=""
            srcset=""
          />
          <span>刷新</span>
        </div>
        <el-popover placement="bottom" width="150" trigger="click">
          <div class="track-headc">
            <el-radio-group v-model="speedValue">
              <el-radio :label="500">0.5X</el-radio>
              <el-radio :label="1000">1.0X</el-radio>
              <el-radio :label="2000">2.0X</el-radio>
              <el-radio :label="4000">4.0X</el-radio>
              <el-radio :label="16000">16.0X</el-radio>
            </el-radio-group>
          </div>
          <div class="track-head-twins-botton2" slot="reference">
            {{ (speedValue / 1000).toFixed(1) }}x
            <i class="el-icon-arrow-down" style="margin-left: 4px"></i>
          </div>
        </el-popover>
        <div
          class="track-head-twins-play-b"
          @click="isStart ? pauseMove() : playTrack()"
        >
          <img
            v-if="isStart"
            src="../../../assets/images/realTimePositioning/track-pause.png"
            alt=""
            srcset=""
          />
          <img
            v-else
            src="../../../assets/images/realTimePositioning/track-play.png"
            alt=""
            srcset=""
          />
          {{ isStart ? "暂停" : "播放" }}
        </div>
      </div>
      <div class="track-head-item">
        <div class="track-head-twins" style="margin-left: 8px">
          <!-- <el-popover placement="bottom" width="150" trigger="click" v-if="currentVehicle.isCommercialVehicles">
            <div class="track-headc">
              <el-checkbox-group v-model="bodyData.alarmTypeList">
                <el-checkbox
                  v-for="(item, index) in alarmTypeCheckList"
                  :key="index"
                  :label="item.alarmName"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
            <div
              class="track-head-twins-botton2"
              slot="reference"
            >
              <img
                src="../../../assets/images/realTimePositioning/track-screen.png"
                alt=""
                srcset=""
              />
              筛选告警类型
            </div>
          </el-popover> -->
          <div
            class="track-head-twins-identical track-head-twins-right"
            style="margin-right: 18px"
          >
            轨迹时间
            <div class="el-date-b">
              <div class="track-head-twins-botton" @click="handleNextDate(-1)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-date-picker
                v-model="bodyData.dateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <!--  -->
              <div
                class="track-head-twins-botton"
                :class="{
                  'track-head-twins-botton-d': bodyData.dateTime == endDateTime,
                }"
                @click="handleNextDate(1)"
              >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div
            class="hour-head-twins-identical hour-head-twins-left"
            style="margin-right: 18px"
          >
            告警级别
            <el-select v-model="bodyData.level" placeholder="请选择">
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="hour-head-twins-identical hour-head-twins-left"
            style="margin-right: 18px"
          >
            处理状态
            <el-select v-model="bodyData.handleState" placeholder="请选择">
              <el-option
                v-for="item in alarmTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="hour-head-twins-identical hour-head-twins-right">
            报警类型
            <el-select v-model="bodyData.alarmTypeIdList" placeholder="请选择">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="track-content">
      <div class="track-content-map" v-loading="isLoading">
        <AMap :center="mapCenter" :zoom="17">
          <!-- 展示地图信息窗体 -->
          <InfoWindow @input="handleCloseWindow"
            v-if="windowInfo.id"
            :lngLat="windowPoint"
            :offset="[-4, -2]"
            :width="236"
            :height="156"
            :showClose="true"
          >
            <div class="window-title">
              告警信息
              <div
                class="window-title-status"
                v-if="windowInfo.handleState != 0"
              >
                已处理
              </div>
            </div>
            <div class="window-info">
              <div class="window-info-label">告警类型：</div>
              <div class="window-info-vlue">{{ windowInfo.alarmName }}</div>
              <div
                class="window-info-status"
                :class="['window-info-status' + windowInfo.level]"
              >
                {{
                  windowInfo.level == 1
                    ? "低"
                    : windowInfo.level == 2
                    ? "中"
                    : "高"
                }}
              </div>
            </div>
            <div class="window-info">
              <div class="window-info-label">车身速度：</div>
              <div class="window-info-vlue">{{ windowInfo.speed }}km/h</div>
            </div>
            <div class="window-info">
              <div class="window-info-label">告警时间：</div>
              <div class="window-info-vlue">
                {{ windowInfo.timeBegin || "--" }}
              </div>
            </div>
            <div class="window-button">
              <div class="window-button-d" @click="isVisible = true">
                点击查看详情
              </div>

              <el-dropdown
                @command="handleCommand($event, alarmData, true)"
                v-if="windowInfo.handleState == 0"
              >
                <div class="window-button-d">处理</div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1" @click.stop=""
                    >确认无风险</el-dropdown-item
                  >
                  <el-dropdown-item command="2" @click.stop=""
                    >误报</el-dropdown-item
                  >
                  <el-dropdown-item command="3" @click.stop=""
                    >风险点</el-dropdown-item
                  >
                  <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </InfoWindow>

          <!-- 轨迹回放 -->
          <MapTrack
            ref="mapTrackRef"
            :path="trackProps.path"
            :speed="speedValue"
            :autoRotation="true"
            :markerOpts="markerOpts"
            :markerOffset="[0, 0]"
            :startMarkerOpts="markerOpts"
            :endMarkerOpts="markerOpts"
            @moving="movingClick"
            @moveEnd="onMoveEnd"
            @onReplacement="onReplacement"
          >
            <template #moveMarker>
              <Iconfont
                name="https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/basic/common/ui/points-icon-go2.png"
                :size="48"
                :rotate="infoProps.params.direction || 0"
              ></Iconfont>
            </template>
            <template #startMarker>
              <Iconfont name="icon-qi" :size="32"></Iconfont>
            </template>
            <template #endMarker>
              <Iconfont name="icon-zhong" :size="32"></Iconfont>
            </template>
          </MapTrack>

          <!-- 聚合点 -->
          <MapClusterer
            ref="clusterObj"
            :points="clusterPoints"
            :minClusterSize="10"
            pointKey="key"
            :markerOffset="[0, 0]"
            @markerClick="onMarkerClick"
          >
            <template #marker="{ point }">
              <!-- <Iconfont
                :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
                :size="32"
                :rotate="point.data.F || 0"
              ></Iconfont>-->
              <!-- <Iconfont name="icon-a-zu517" :size="32" :rotate="point.data.F || 0"></Iconfont> -->
              <Iconfont
                :size="18"
                name="https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/basic/common/ui/points-icon-alarm.png"
              ></Iconfont>
            </template>
          </MapClusterer>
        </AMap>
      </div>
      <div class="track-content-alarm" v-loading="isAlarmLoading">
        <div class="track-content-alarm-statistics">
          <div @click="topTab = ''" class="track-content-alarm-statistics-item">
            <span>{{ statisticsObj.total || "--" }}</span>
            <div>告警总数</div>
          </div>
          <div @click="topTab = 1" class="track-content-alarm-statistics-item">
            <span>{{ statisticsObj.handleTotal || "--" }}</span>
            <div>已处理</div>
          </div>
          <div @click="topTab = 0" class="track-content-alarm-statistics-item">
            <span>{{ statisticsObj.notHandleTotal || "--" }}</span>
            <div>未处理</div>
          </div>
        </div>
        <div class="track-content-alarm-y">
          <div
            :class="['track-content-alarm-item', windowInfo.id == item.id ? 'track-content-alarm-item-select' : '']"
            v-for="(item, index) in (tableData || []).filter(vv=> topTab === '' || vv.handleState == topTab)"
            :key="index"
            @click="handleAlarmDetails(item)"
          >
            <div
              :class="[
                'track-content-alarm-item-hear',
                `track-content-alarm-item-hear${item.level}`,
              ]"
            >
              <div class="track-content-alarm-item-status">
                <div>{{ item.alarmTypeName }}</div>
                <div :class="['track-content-alarm-item-status-' + item.level]">
                  {{ item.level == 1 ? "低" : item.level == 2 ? "中" : "高" }}
                </div>
              </div>
              <div>{{ item.timeBegin }}</div>
            </div>
            <div class="track-content-alarm-item-dynamic">
              <div class="track-content-alarm-item-dynamic-label">
                告警位置：
              </div>
              <div class="track-content-alarm-item-dynamic-value">
                <AddressText
                  :autoScroll="false"
                  :lng="item.lon"
                  :lat="item.lat"
                />
              </div>
            </div>
            <div
              class="track-content-alarm-item-dynamic"
              style="padding-bottom: 10px"
            >
              <div class="track-content-alarm-item-dynamic-label">
                车身速度：
              </div>
              <div class="track-content-alarm-item-dynamic-value">
                <span>{{ item.speed }} km/h</span>
                <div>
                    <span v-if="item.handler"
                      style="
                        color: #999999;
                        border-right: 1px solid #999999;
                        padding-right: 4px;
                      "
                      >{{ item.handler }}
                    </span>
                <el-dropdown @command="handleCommand($event, item, true)">
                  <el-button
                    v-if="item.handleState == 0"
                    style="background: #0080ff; border: 1px solid #0080ff"
                    type="primary"
                    size="mini"
                    >处理
                  </el-button>
                    <el-button v-else
                      style="color: #0080ff; margin-left: 4px"
                      type="text"
                      size="mini"
                    >
                      <span style="font-size: 14px">重新处理</span>
                    </el-button>

                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1" @click.stop=""
                      >确认无风险</el-dropdown-item
                    >
                    <el-dropdown-item command="2" @click.stop=""
                      >误报</el-dropdown-item
                    >
                    <el-dropdown-item command="3" @click.stop=""
                      >风险点</el-dropdown-item
                    >
                    <!-- <el-dropdown-item command="4">电话联系</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
                </div>
                <!-- <span
                  class="track-content-alarm-item-dynamic-value-dropdown"
                  v-else
                  >已处理</span
                > -->
              </div>
            </div>
          </div>
        </div>

        <div class="vacancy" v-if="!tableData || !((tableData || []).filter(vv=> topTab === '' || vv.handleState == topTab)).length">
          <img
            class="vacancy-icon"
              src="../../../assets/images/empty-20240628.png"
            alt=""
            srcset=""
          />
          <div class="vacancy-text">暂无告警数据</div>
        </div>
      </div>
    </div>
    <disposeDialog
      v-model="isDispose"
      :equipmentData="currentVehicle"
      :alarmData="alarmData"
      :conditionObj="conditionObj"
      equipmentType="7"
      @changeList="isWindowHandled ? (isWindowHandled = false, alarmData.handleState = 1, alarmData.handler = '风险点', (windowInfo.id == alarmData.id ? (windowInfo.handleState = 1, windowInfo.handler = '风险点'): '' )) : getList()"
    />
    <alarmDetailsDialog v-model="isVisible" :editDetail="windowInfo" />
  </div>
</template>

<script>
import AMap, { MapTrack, InfoWindow, MapClusterer } from "@/components/AMap";
import dayjs from "dayjs";
import GPS from "@/utils/address";
import { getAddresses } from "@/components/AMap/utils";
import disposeDialog from "@/views/riskControlManagement/components/disposeDialog";
import alarmDetailsDialog from "@/views/RealTimePositioning/components/alarmDetailsDialog";
import {
  SCAlramList,
  getAlarmConf,
  getPCZKAlarmDetail,
} from "@/api/vehicleApi";
import { getTrackInfo } from "@/api/getManagementData.js";
import AddressText from "@/components/AddressText";
import { riskHandle } from "@/api/SystemData";
export default {
  components: {
    MapTrack,
    AMap,
    InfoWindow,
    MapClusterer,
    AddressText,
    disposeDialog,
    alarmDetailsDialog,
  },
  computed: {
    markerOpts() {
      return {
        offset: [-2, 8],
      };
    },
    // 聚合点
    clusterPoints() {
      const { alarmMarkerLst } = this;
      return alarmMarkerLst.map((obj, indd) => {
        const point = GPS.delta(obj.lat, obj.lon);
        return {
          key: obj.id || indd,
          lng: point.lon,
          lat: point.lat,
          data: obj,
        };
      });
    },
  },
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
    conditionObj: {
      type: Object,
      default: null,
    },
  },
  watch: {
    bodyData: {
      handler(v) {
        this.getList();
      },
      deep: true,
      immediate: false,
    },
    currentVehicle: {
      handler(v, old) {
        this.init();
        // this.getList(true);
      },
      immediate: true,
    },
  },
  data() {
    return {
      mapCenter: [116.482169, 39.998932],
      topTab: "",
      windowPoint: [],
      // 播放速度
      speedValue: 1000,
      trackProps: {
        // 回放参数
        path: [],
      },
      dataList: [], // 记录初始数据
      tableData: [], // table 数据
      height: 0, // 虚拟table高度 必须要
      rowHeight: 55, // 默认行高
      infoProps: {
        // 窗体参数
        time: "",
        visible: false,
        lngLat: [114.340716, 23.012966],
        params: {},
        lon: "",
        lat: "",
        address: "",
      },
      windowInfo: {},
      isWindowHandled: false,
      bodyData: {
        // dateTime: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        dateTime: dayjs().add(-1, "day").format("YYYY-MM-DD"),
        speedValue: 1,
        alarmTypeList: [], //
        handleState: "",
      },
      endDateTime: dayjs().format("YYYY-MM-DD"),
      isStart: false,
      currentMarker: null,
      alarmMarkerLst: [],
      isVisible: false,
      isLoading: false,
      isAlarmLoading: false,
      isStop: false,
      statisticsObj: {},
      levelOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "低",
          value: "1",
        },
        {
          label: "中",
          value: "2",
        },
        {
          label: "高",
          value: "3",
        },
      ],
      alarmTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未处理",
          value: "0",
        },
        {
          label: "已处理",
          value: "1",
        },
      ], //
      typeOptions: [],
      isDispose: false,
      alarmData: {}, //
    };
  },
  created() {},
  methods: {
    init() {
      getAlarmConf({ flag: this.currentVehicle.isCommercialVehicles ? 1 : "" })
        .then((res) => {
          if (res.obj && res.obj.length) {
            this.typeOptions = res.obj.map((val) => {
              return {
                label: val.alarmName,
                value: val.alarmId,
              };
            });
            this.typeOptions.unshift({
              label: "全部",
              value: "",
            });
          } else {
            this.typeOptions = [];
          }
        })
        .catch((err) => {
          this.typeOptions = [];
        });
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async setAddresses(points, type) {
      const result = await getAddresses(points);
      result.forEach(({ tag, address }) => {
        this.dataList[tag][["address", "address1"][type]] = address;
      });
    },
    // 播放轨迹点击事件
    async playTrack() {
      const { mapTrackRef } = this.$refs;
      if (!this.isStart && this.isStop) {
        mapTrackRef.resumeMove();
        this.isStart = true;
        this.isStop = false;
        return false;
      }
      if (!this.isStart || this.percentage === 100) {
        await mapTrackRef.resetMove();

        mapTrackRef.startMove();
      }

      this.isStart = true;

      if (this.isStart) mapTrackRef.resumeMove();
    },

    // 暂停播放
    pauseMove() {
      this.isStart = false;
      this.isStop = true;
      const { mapTrackRef } = this.$refs;
      mapTrackRef.pauseMove();
    },

    /**
     * 计算播放进度
     * moveToIndex: 下一个位置下标
     */
    movingClick(passedPath, moveToIndex, e) {
      const { tableData } = this;

      if (moveToIndex < tableData.length) {
        // this.windowPoint = passedPath[passedPath.length - 1];
        this.setMoveMarker(moveToIndex - 1);
      }
    },
    setMoveMarker(index) {
      const { tableData } = this;
      if (index < tableData.length) {
        // 移动的位置点信息
        const pointObj = tableData[index];
        this.infoProps.params = pointObj;
        this.infoProps.lngLat = [pointObj.lon, pointObj.lat];
        this.infoProps.address = pointObj.address;
        this.infoProps.visible = true;

        const percentage =
          Math.floor(((index + 1) / tableData.length) * 10000) / 100;
        this.percentage = percentage;

        this.scrollIntoView(pointObj, index);
      }
    },
    /**
     * table 设置当前行, 并滚动到可视区
     */
    async scrollIntoView(row, index) {
      const { rowHeight } = this;
      const { uTableObj } = this.$refs;
      if (!uTableObj) return;

      // 距离顶部高度
      const top = Math.max(0, (index + 0) * rowHeight);

      uTableObj.pagingScrollTopLeft(top, 0);
      await this.$nextTick();
      uTableObj.setCurrentRow(row);
    },
    onMoveEnd() {
      const { tableData, trackProps } = this;
      // TODO: 实现播放结束

      this.isStart = false;

      // this.windowPoint = trackProps[trackProps.path.length - 1];
      tableData.length ? this.setMoveMarker(tableData.length - 1) : "";
    },
    onReplacement() {
      this.isStart = false;
    },
    async getList(type) {
      if (type == "reset") {
        return false;
      };
      this.topTab = '';
      const { mapTrackRef } = this.$refs;
      mapTrackRef?.replacementMove();
      let alarmTypeList =
        this.bodyData.alarmTypeList && this.bodyData.alarmTypeList.length
          ? this.alarmTypeCheckList
              .filter((val) =>
                this.bodyData.alarmTypeList.includes(val.alarmName)
              )
              .map((val) => val.alarmId)
              .toString()
          : [];
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        plate: this.currentVehicle.P,
        isCommercialVehicles: true,
        beginTime: dayjs(this.bodyData.dateTime).format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs(this.bodyData.dateTime).format("YYYY-MM-DD 23:59:59"),
        filterTime: 0,
        posStatus: 1,
        stopStatus: 1,
        stopFlag: 0,
        alarmTypeList,
        ...this.bodyData,
      };
      if (!data.vehicleId) {
        return false;
      }
      this.isAlarmLoading = true;
      SCAlramList({
        ...data,
        alarmTimeBegin: data.beginTime,
        alarmTimeEnd: data.endTime,
        beginTime: null,
        endTime: null,
        pageSize: 999999,
      })
        .then((res) => {
          this.isAlarmLoading = false;
          this.alarmMarkerLst = res.obj.SCAlarm;
          this.tableData = res.obj.SCAlarm;
          this.dataList = this.tableData; // 记录查询的初始数据
          this.statisticsObj = res.obj;
        })
        .catch((err) => {
          this.isAlarmLoading = false;
          this.alarmMarkerLst = [];
          this.statisticsObj = {};
        });
      this.isLoading = true;
      const result = await getTrackInfo(data);
      this.isLoading = false;
      let path = result.extend.lonLat.filter(
        (val) => val[0] != 0 && val[1] != 0
      );
      this.trackProps.path = path.map((item) => {
        const point = GPS.delta(item[1], item[0]);
        return {
          lng: point.lon,
          lat: point.lat,
        };
      });
    },
    async onMarkerClick(e) {
      const point = GPS.delta(e.data.lat, e.data.lon);
      this.windowPoint = [point.lon, point.lat];
      let res = await getPCZKAlarmDetail({
        alarmId: e.data.id,
        day: this.bodyData.dateTime,
        flag: this.currentVehicle.isCommercialVehicles ? 1 : "",
      });
      const ind = this.tableData.findIndex(vv=> vv.id == e.data.id);
      this.alarmData = this.tableData[ind]
      if (res.flag == 1 && res.obj) {
        this.windowInfo = { ...res.obj, ...e.data };
      } else {
        this.windowInfo = {};
      }
    },
    handleCommand(e, data, type) {
      const h = this.$createElement;
      const that = this;
      switch (e) {
        case "1":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 7, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: that.$store.state.userInfo.userId, //处理用户id
            createUser: that.$store.state.userInfo.name, //处理用户名
            projectName: that.conditionObj.projectName, //项目名称
            projectIds: that.conditionObj.projectId, //项目id
            customName: that.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              type ? (data.handleState = 1, data.handler = "确认无风险",(this.windowInfo.id == data.id ? (this.windowInfo.handleState = 1, this.windowInfo.handler = '确认无风险'): '' )) : this.getList();
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此警告确认无风险？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: data.vehicleId, //车辆id
          //         plate: data.plate, //车牌
          //         dataType: 7, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "确认无风险", //处理方式(确认无风险、误报，风险点)
          //         alarmId: data.id, //告警id
          //         alarmType: data.alarmType, //告警类型
          //         alarmTime: data.timeBegin, //告警时间
          //         remark: "", //处理内容
          //         createUserId: that.$store.state.userInfo.userId, //处理用户id
          //         createUser: that.$store.state.userInfo.name, //处理用户名
          //         projectName: that.conditionObj.projectName, //项目名称
          //         projectIds: that.conditionObj.projectId, //项目id
          //         customName: that.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           type ? (data.handleState = 1) : "";
          //           this.getList();
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理失败");
          //           done();
          //         });
          //       // setTimeout(() => {
          //       //   done();
          //       //   setTimeout(() => {
          //       //     instance.confirmButtonLoading = false;
          //       //   }, 300);
          //       // }, 3000);
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "2":
          riskHandle({
            vehicleId: data.vehicleId, //车辆id
            plate: data.plate, //车牌
            dataType: 7, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
            interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
            alarmId: data.id, //告警id
            alarmType: data.alarmType, //告警类型
            alarmTime: data.timeBegin, //告警时间
            remark: "", //处理内容
            createUserId: this.$store.state.userInfo.userId, //处理用户id
            createUser: this.$store.state.userInfo.name, //处理用户名
            projectName: this.conditionObj.projectName, //项目名称
            projectIds: this.conditionObj.projectId, //项目id
            customName: this.conditionObj.customName, //客户名称
          })
            .then((res) => {
              //instance.confirmButtonLoading = false;
              this.$message.success("处理成功");
              type ? (data.handleState = 1, data.handler = "误报",(this.windowInfo.id == data.id ? (this.windowInfo.handleState = 1, this.windowInfo.handler = '误报'): '' )) : this.getList();
              
              //done();
            })
            .catch((err) => {
              //instance.confirmButtonLoading = false;
              this.$message.error("处理失败");
              //done();
            });
          // this.$msgbox({
          //   title: "",
          //   message: h("div", null, [
          //     h(
          //       "img",
          //       {
          //         style:
          //           "display: block;margin: 0 auto 10px;width:60px;height:60px;",
          //         attrs: {
          //           src: require("@/assets/images/newRiskControl/cl-gj-icon.png"),
          //           alt: "Alternative Text",
          //         },
          //       },
          //       null
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-weight: bold;font-size: 16px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "提示 "
          //     ),
          //     h(
          //       "div",
          //       {
          //         style:
          //           "font-size: 14px;color: #333333;text-align: center;margin-bottom: 24px;",
          //       },
          //       "此告警是否误报？"
          //     ),
          //   ]),
          //   showCancelButton: true,
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   beforeClose: (action, instance, done) => {
          //     if (action === "confirm") {
          //       instance.confirmButtonLoading = true;
          //       instance.confirmButtonText = "执行中...";
          //       riskHandle({
          //         vehicleId: data.vehicleId, //车辆id
          //         plate: data.plate, //车牌
          //         dataType: 7, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
          //         interveneWay: "误报", //处理方式(确认无风险、误报，风险点)
          //         alarmId: data.id, //告警id
          //         alarmType: data.alarmType, //告警类型
          //         alarmTime: data.timeBegin, //告警时间
          //         remark: "", //处理内容
          //         createUserId: this.$store.state.userInfo.userId, //处理用户id
          //         createUser: this.$store.state.userInfo.name, //处理用户名
          //         projectName: this.conditionObj.projectName, //项目名称
          //         projectIds: this.conditionObj.projectId, //项目id
          //         customName: this.conditionObj.customName, //客户名称
          //       })
          //         .then((res) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.success("处理成功");
          //           this.getList();
          //           type ? (data.handleState = 1) : "";
          //           done();
          //         })
          //         .catch((err) => {
          //           instance.confirmButtonLoading = false;
          //           this.$message.error("处理失败");
          //           done();
          //         });
          //     } else {
          //       done();
          //     }
          //   },
          // }).then((action) => {});
          break;
        case "3":
          this.isDispose = true;
          this.alarmData = data;
          type ? (this.isWindowHandled = true) : (this.isWindowHandled = false);
          // this.$emit('changeDialog', 'isDispose', this.currentVehicle, this.equipmentType)
          break;
        case "4":
          // this.isDispose = true;
          this.$emit(
            "changeDialog",
            "isDispose",
            this.currentVehicle,
            this.equipmentType
          );
          break;
      }
    },
    handleAlarmDetails(e) {
      if (!this.currentVehicle.isCommercialVehicles) return false;
      const point = GPS.delta(e.lat, e.lon);
      this.mapCenter = [point.lon, point.lat];
      this.onMarkerClick({
        data: e,
      });
    },
    handleCloseWindow(e) {
      if (!e) {
        this.windowInfo = {};
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.track {
  height: 100%;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 12px;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;

    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      &-botton2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 8px;
        cursor: pointer;
        font-size: 12px;
        color: #333333;
        margin-right: 16px;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      &-play-b {
        display: flex;
        align-items: center;
        width: 74px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        margin-right: 16px;
        background: #0080ff;
        border-radius: 16px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-left: 4px;
        }
      }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-headc {
    min-width: 126px;
    max-height: 240px;
    overflow-y: auto;
    ::v-deep .el-checkbox {
      display: block;
      margin: 0;
      margin-bottom: 14px;
    }
    ::v-deep .el-radio {
      display: block;
      margin: 0;
      margin-bottom: 14px;
    }
  }
  &-content {
    height: calc(100% - 64px);
    display: flex;
    &-alarm {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 383px;
      height: 100%;
      padding: 16px;
      padding-top: 0;
      background: #ffffff;
      &-y {
        // height: calc(100% - 42px);
        // height: 100%;
        flex: 1;
        padding-top: 16px;
        overflow-y: auto;
      }
      &-item {
        margin-bottom: 10px;
        background: #ffffff;
        //box-shadow: 0px 0px 6px 1px rgba(0, 128, 255, 0.1);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        cursor: pointer;
        &-status {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 14px;
          color: #333333;
          &-2 {
            width: 46px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            background: #fda835;
            border-radius: 20px;
          }
          &-1 {
            width: 46px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            background: #409eff;
            border-radius: 20px;
          }
          &-3 {
            width: 46px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            background: #f64a2d;
            border-radius: 20px;
          }
        }
        &-hear {
          position: relative;
          height: 42px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 15px;
          padding: 0 16px;
        }
        &-hear::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1px;
          width: 4px;
          height: 41px;
          background: #f64a2d;
          border-radius: 5px 5px 5px 5px;
        }
        &-hear1::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1px;
          width: 4px;
          height: 41px;
          border-radius: 5px 5px 5px 5px;
          background: #409eff;
        }
        &-hear2::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1px;
          width: 4px;
          height: 41px;
          border-radius: 5px 5px 5px 5px;
          background: #fda835;
        }
        &-hear3::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1px;
          width: 4px;
          height: 41px;
          border-radius: 5px 5px 5px 5px;
          background: #f64a2d;
        }
        &-dynamic {
          display: flex;
          margin-top: 9px;
          padding: 0 16px;
          &-label {
            font-size: 14px;
            color: #999999;
          }
          &-value {
            display: flex;
            justify-content: space-between;
            flex: 1;
            font-size: 14px;
            color: #333333;
            &-dropdown {
              font-size: 14px;
              color: #999999;
            }
          }
        }
        &-statistics {
          width: 100%;
          display: flex;
          align-items: center;
          height: 40px;
          font-size: 12px;
          color: #666666;
          div {
            width: 33.3%;
            display: flex;
            align-items: center;
            justify-content: left;
            padding-left: 8px;
            border-left: 1px solid #dee3e6;
          }
        }
        &-select {
          background: #EFF7FF;
          box-shadow: 0px 0px 6px 1px rgba(0,128,255,0.1);
          border: 1px solid #0080FF;
        }
      }
      &-item:hover {
        box-shadow: 0px 0px 10px 1px rgba(0,128,255,0.3);
        border: 1px solid #DEE3E6;
      }
      &-statistics {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 12px 16px;
        background: #eff7ff;
        cursor: pointer;
        &-item {
          flex: 1;
          font-size: 14px;
          color: #333333;
          text-align: center;
          span {
            display: block;
            font-weight: bold;
            font-size: 14px;
            color: #0080ff;
            margin-bottom: 4px;
          }
          div {
          }
        }
      }
      .vacancy {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% + 60px);
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        &-icon {
          width: 99px;
          height: 76px;
        }
        &-text {
          font-size: 15px;
          color: #333333;
          margin-top: 5px;
        }
      }
    }
    &-map {
      flex: 1;
      height: 100%;
      ::v-deep .WindowContent {
        border: none;
        padding: 12px 10px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 1px rgba(51, 51, 51, 0.12);
        border-radius: 10px 10px 10px 10px;
      }
      .window {
        &-title {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          margin-bottom: 8px;
          &-status {
            height: 19px;
            line-height: 19px;
            font-size: 12px;
            color: #00bc0d;
            padding: 0 8px;
            margin-left: 8px;
            background: rgba(0, 188, 13, 0.1);
            border-radius: 10px 10px 10px 10px;
          }
        }
        &-info {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &-label {
            font-size: 14px;
            color: #999999;
          }
          &-value {
            font-size: 14px;
            color: #333333;
          }
          &-status {
            position: absolute;
            top: 0;
            right: 0;
            width: 43px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            border-radius: 20px;
          }
          &-status1 {
            background: #409eff;
          }
          &-status2 {
            background: #fda835;
          }
          &-status3 {
            background: #f64a2d;
          }
        }
        &-button {
          display: flex;
          justify-content: space-around;
          align-items: center;
          &-d {
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            color: #377dff;
          }
        }
      }
    }
  }
}
.item-select:hover {
  box-shadow: 0px 0px 6px 1px rgba(0, 128, 255, 0.1);
}
.current-item-select {
  background: rgba(239, 247, 255, 1);
  box-shadow: 0px 0px 6px 1px rgba(0, 128, 255, 0.1);
  border: 1px solid #0080ff;
}
</style>
