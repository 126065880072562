import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';

import {
   resolveMedia,
} from '@/utils/adasUtil';
import { AdasAlarmTypes } from '@/config/adasAlarmtype';


const axios = createHttp(appConfig.base2GUrl);
const axios2 = createHttp(appConfig.base2GUrl2);


const get = (action, options) => axios.get(`monitor/${ action }.do`, options);
const statistics_get = (action, options) => axios2.get(`${ action }`, options); //统计服务
const post = (action, data, options) => axios.post(`monitor/${ action }.do`, data, {
   headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
   ...options
});

/**
 * 查看图片
 */
export const pictureShow = path => get('pictureShow', { responseType: 'blob', params: { path } }).then(res => URL.createObjectURL(res));

/**
 * 下载图片
 */
export const pictureDownload = path => get('pictureDownload', { responseType: 'blob', params: { path } }).then(res => URL.createObjectURL(res));

/**
 * 查询图片
 */
export const pictureQuery = () => get('pictureQuery');

/**
 * 抓拍
 * shootId
 * terminalNo
 * vehicleId
 */
export const shootCommandDown = params => get('shootCommandDown', { params });

/**
 * 车组指令下发
 * groupId
 * cmdType
 * commandMsg
 */
export const commandDownByGroup = params => get('commandDownByGroup', { params });

/**
 * 命令下发
 * vehicleId
 * terminalNo
 * cmd
 * commandMsg
 */
export const commandDown = params => get('commandDown', { params });

/**
 * 查询下发指令
 * time : >0
 */
export const queryTextUpAndCmdDownInfo = (time = 0) => get('queryTextUpAndCmdDownInfo', { params: { time } });

/**
 * 报警处理
 * @param {*} J
 * @param {*} R
 * @param {*} arr , 处理的报警记录，[{"A":"287037a6-7621-4e54-81aa-8fd20b97ad52","G":12807,"T":"摄像头丢失报警","V":464927},{"A":"65784502-28a0-48f0-8442-90562062bfd4","G":12807,"T":"摄像头丢失报警","V":464921},{"A":"7228aa04-80cc-41b6-88df-3f187e5fff08","G":12807,"T":"视频遮挡报警","V":464921}]
 */
export const alarmProcess = (J, R, arr) => {
   const data = `json=${ JSON.stringify({
      obj: arr,
      J: J,
      R: R
   }) }`;
   return post('alarmProcess', data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
   });
};

export const handleAdas = (adas) => {
   return adas.filter(item =>
      // item.adasFileList &&
      Object.keys(item.alarmAttribute || {}).length)
      .map(item => {
         //报警类型
         const alarmTypes = Object.values(item.alarmAttribute)
            .filter(type => AdasAlarmTypes.some(p => p.value === type.alarmType))
            .map(type => ({
               alarmType: type.alarmType, //报警类型
               alarmSource: type.alarmSource, //报警来源
               alarmLevel: type.alarmLevel, //报警等级
               typeLabel: AdasAlarmTypes.find(p => p.value === type.alarmType).label, //报警类型文本
            }));

         //报警文件列表
         const adasFileList = (item.adasFileList || [])
            .filter(file => file.fileSize && !file.fileName.toLowerCase().endsWith('.bin'))
            .map(file => ({
               channel: file.channel,
               startTime: new Date(file.startTime).format('yyyy-MM-dd HH:mm:ss'),
               endTime: new Date(file.endTime).format('yyyy-MM-dd HH:mm:ss'),
               fullPath: `${ item.serverPath }${ item.filePath }${ file.fileName }`,
               mediaType: resolveMedia(file.media).desc,
               result: item.result,
               threshold: item.threshold,
               comptrastType: item.comptrastType
            }));

         //报警级别
         const adasLevel = ['', '一级报警', '二级报警'][item.adasLevel];


         //报警开始时间,结束时间
         const alarmStartTimeStr = new Date(item.alarmStartTime).format('yyyy-MM-dd HH:mm:ss');
         const alarmEndTimeStr = new Date(item.alarmEndTime).format('yyyy-MM-dd HH:mm:ss');

         //包含驾驶员替换异常
         if (alarmTypes.some(type => type.alarmType === 'ReplaceAbnormal') &&
            adasFileList.length >= 2) {
            adasFileList[0].fullPath2 = adasFileList.splice(1, 1)[0].fullPath; //驾驶员照片
         }

         return {
            alarmId: item.alarmId, //报警ID
            vehicleId: item.vehicleId, //车辆ID
            groupId: item.groupId, //车组ID
            plate: item.vCph, //设备名称
            groupName: item.groupName, //车组名称
            terminalNo: item.vSim, //终端号
            phone: item.phone, //电话号码
            GPhone: item.GPhone, //车组电话号码
            speed: item.speed, //速度
            // CL211客户专有
            emergencyContact: item.emergencyContact || '', //紧急联系人
            driverPhone: item.driverPhone || '', //司机联系电话
            // CL211客户专有
            similar: item.similar, //相似度
            result: item.result, //对比结果 ['匹配成功','匹配失败','超时','没有启用该功能','连接异常','无指定人脸图片','无人脸库']
            threshold: item.threshold, //
            comptrastType: item.comptrastType, //对比类型 ['插卡比对','巡检比对','点火比对','离开返回比对']
            lon: item.lon, //经度
            lat: item.lat, //纬度
            alarmStartTime: item.alarmStartTime, //报警开始时间
            alarmEndTime: item.alarmEndTime, //报警结束时间
            alarmTypes, //报警类型集合, 接口字段：alarmAttribute; [{alarmType:'报警类型',alarmSource:'报警来源',alarmLevel:'报警等级',typeLabel:'含义'}]
            adasFileList, //附件集合 [{channel:'通道号',startTime:'开始时间',endTime:'结束时间',fullPath:'附件地址',mediaType,result,threshold,comptrastType}];fullPath:serverPath+filePath+fileName
            alarmStartTimeStr,
            alarmEndTimeStr,
            adasLevel, //['', '一级报警', '二级报警']
            snowflakeId: item.snowflakeId,
         };
      });
};

/**
 * 获取ADAS报警
 * @param {*} time
 */
export const getAdasAlarm = (time = 0) => {
   return get('getAdasAlarm', { params: { time, userId: '' } })
      .then(res => {
         const { flag, obj } = res;
         let time;
         let alarmList = [];
         if (flag === 1 && obj && obj.adas) {
            time = obj.time;
            const { adas } = obj;
            console.time('getadasalarm');
            alarmList = handleAdas(adas);

            console.timeEnd('getadasalarm');
         }
         return { flag, alarmList, time };
      });
};

/**
 * adas报警 过检
 * @param {*} time 
 */
export const getAdasInfo = (time = 0) => {
   return get('getAdasInfo', { params: { time } })
      .then(res => {
         const { flag, obj: { adas } } = res;
         let alarmList = [];
         if (flag && adas) {
            console.time('getadasinfo');
            alarmList = adas
               .filter(item => AdasAlarmTypes.some(p => p.value === item.adasAlarmType))
               .map(item => {
                  //报警类型
                  const alarmTypes = [{
                     alarmType: item.adasAlarmType, //报警类型
                     alarmLevel: item.alarmLevel, //报警等级
                     alarmSource: item.alarmSource, //报警来源
                     typeLabel: AdasAlarmTypes.find(p => p.value === item.adasAlarmType).label, //报警类型文本
                  }];

                  //报警文件列表
                  const adasFileList = [...(item.aviPath || []), ...(item.path || []), ...(item.driverPath || [])]
                     .filter(file => !file.toLowerCase().endsWith('.bin'))
                     .map(file => ({
                        // channel: file.channel,
                        startTime: item.startTime,
                        endTime: item.endTime,
                        fullPath: `${ item.ftpServerPath }${ file }`,
                        mediaType: file.toLowerCase().endsWith('.mp4') ? '音视频' : '图片',
                        result: item.result,
                        threshold: item.threshold,
                        comptrastType: item.comptrastType
                     }));


                  //报警级别
                  const adasLevel = ['', '一级报警', '二级报警'][item.adasLevel];

                  //报警开始时间,结束时间
                  const alarmStartTimeStr = item.startTime;
                  const alarmEndTimeStr = item.endTime;
                  const alarmStartTime = new Date(item.startTime).getTime();
                  const alarmEndTime = new Date(item.endTime).getTime();

                  //包含驾驶员替换异常
                  if (alarmTypes.some(type => type.alarmType === 'ReplaceAbnormal') &&
                     adasFileList.length >= 2) {
                     adasFileList[0].fullPath2 = adasFileList.splice(1, 1)[0].fullPath; //驾驶员照片
                  }

                  return {
                     alarmId: item.alarmId,
                     vehicleId: item.vehicleId,
                     groupId: item.groupId,
                     plate: item.plate,
                     plateColor: item.plateColor,
                     groupName: item.groupName,
                     terminalNo: item.terminalNo,
                     // phone: item.phone,
                     // GPhone: item.GPhone,
                     speed: item.speed,
                     similar: item.similar,
                     result: item.result,
                     threshold: item.threshold,
                     comptrastType: item.comptrastType,
                     lon: item.lon,
                     lat: item.lat,
                     alarmTypes,
                     adasFileList,
                     alarmStartTime,
                     alarmEndTime,
                     alarmStartTimeStr,
                     alarmEndTimeStr,
                     adasLevel
                  };
               });
            console.timeEnd('getadasinfo');
         }
         return { flag, alarmList };
      });
};

/**
 * 查询报警信息
 * @param {*} time
 * // A: "f850dacc24234b3b976f1c1018c5c19e2010" //报警ID
        // AN: 1　　　//报警次数
        // D: "2020-10-20 10:30:43"　//报警开始时间
        // ET: "2020-10-20 10:32:23"　//报警结束时间
        // G: 37206　　//车组ID
        // GPhone: ""　　//车组电话
        // H: 0　　//
        // J: 118.102384　　// 经度
        // W: 33.899748 　//纬度
        // LA: 100　　// 持续时间
        // M: ""　　//
        // N: "zl0163"　　// 车组名称
        // P: "苏CG9U86"　　// 设备名称
        // S: 81　　// 速度
        // T: "超速报警"　　// 报警类型含义
        // TEN: "overSpeed"　　//报警类型
        // V: 148884　　// 车辆ID
        // alarmLevel: "严重报警" 　//报警等级
        // alarmSource: 1  　//　报警来源
        // phone: ""  　//　联系电话
 */
export const alarmQuery = ({ time = 0, number = 200, alarmType = 'alarm', type = "" }) => get('alarmQuery', { params: { callback: '', time, number, alarmType, type } });

/**
 * 根据用户获取统计车辆信息
 */
export const getUserVheicleState = params => statistics_get('datavisual/nowVehilceState', { params: { ...params, userId: '' } });

/**
 * 根据车组获取统计车辆信息
 * @param {*} groupIds
 */
// export const getGroupVehNum = groupIds => get('getGroupVehNum', { params: { groupIds: groupIds.join(',') } })
export const getGroupVehNum = groupIds => post('getGroupVehNum', `groupIds=${ groupIds.join(',') }`);

/**
 * 获取播放视频车辆信息
 * @param {*} vehicleIds
 */
export const getVideoState = vehicleIds => get('getVideoState', { params: { gidVid: vehicleIds.join(',') } });

/**
 * 加载车辆信息
 * @param {*} groupIds
 * @param {*} pmt
 * @param {*} vmt
 */
// export const loadVehicles = (groupIds, pmt = 0, vmt = 0) => get('loadVehicles', { params: { ids: groupIds.join(','), posTime: pmt, vehicleTime: vmt } })
export const loadVehicles = ({ groupIds, pmt = 0, vmt = 0, state = 0, pageNumber = 0, pageSize = 0, isCommercialVehicles = '' }) => post('loadVehicles', `ids=${ groupIds.join(',') }&posTime=${ pmt }&vehicleTime=${ vmt }&state=${ state }&pageNumber=${ pageNumber }&pageSize=${ pageSize }&isCommercialVehicles=${isCommercialVehicles}`);

// export const loadVehicles = (groupIds, pmt = 0, vmt = 0) => get('loadVehicles', { params: { ids: groupIds.join(','), posTime: pmt, vehicleTime: vmt } })
export const getDeviceInfo = ({ pmt = 0, vmt = 0, vehicleId = 0, pageNumber = 0, pageSize = 0 }) => post('loadVehicles', `vId=${ vehicleId }&pageNumber=1&pageSize=99`);

/**
 * 查询电子运单
 * @param {*} params.vehicleId //车辆ID 
 */
export const getVehicleOrder = (params) => get('getVehicleOrder', { params });

/**
 * 气泡框数据透传
 * @param {*} params.vehicleId
 */
export const getVehicletextup = (params) => get('getVehicletextup', { params });

/**
 * 加载车组信息
 * @param {*} gmt
 */
export const loadGroups = (gmt = 0) => get('loadGroups', { params: { updateTime: gmt, platform: 'PC' } });

// export const loadGroups = (gmt = 0) => {
//   let sr = 'https://www.easy-mock.com/mock/5b74f23436e7a2495f94b25f/bysk/vgoup'
//   return createAxios().get(sr)
// }

/**
 * 区域查车
 * @param {*} lon1
 * @param {*} lat1
 * @param {*} lon2    
 * @param {*} lat2   
 * @param {*} radius   
 * @param {*} shape   //矩形0 圆形1 
 * @param {*} pageNumber 第几页
 * @param {*} pageSize  每页条数
 */
export const regionCheck = (lon1, lat1, lon2, lat2, radius, shape, pageNumber = 1, pageSize = 50) => get('regionCheck', { params: { lon1, lat1, lon2, lat2, radius, shape, pageNumber, pageSize } });



/**
 * 历史区域查车
 * @param {JSON} val 
 */
export const areaVehicleCtrl = (val) => axios.get('areaVehicleCtrl/getAreaVehicle.do', { params: val });


/**
 * 车辆行停事件
 * @param {*} params 
 */
export const getDrivingParkingEvent = (params) =>
   axios.get('stopDrivingVideo/getDriverParkingAlarm.do', { params })
      .then(res => {
         if (!(res.flag && res.obj)) return null;
         const { dsa, time } = res.obj;

         const alarmList = dsa.map(item => {

            const eventType = ['行车事件', '停车事件'][item.alarmTypeList[0] * 1];

            //报警开始时间,结束时间
            const alarmStartTimeStr = new Date(item.alarmStartTime).format('yyyy-MM-dd HH:mm:ss');
            const alarmEndTimeStr = new Date(item.alarmEndTime).format('yyyy-MM-dd HH:mm:ss');

            const adasFileList = (item.adasFileList || [])
               .filter(file => !file.fileName.toLowerCase().endsWith('.bin'))
               .map(file => ({
                  channel: file.channel,
                  startTime: new Date(file.startTime).format('yyyy-MM-dd HH:mm:ss'),
                  endTime: new Date(file.endTime).format('yyyy-MM-dd HH:mm:ss'),
                  fullPath: `${ item.serverPath }${ item.filePath }${ file.fileName }`,
                  mediaType: resolveMedia(file.media).desc,
                  result: item.result,
                  threshold: item.threshold,
                  comptrastType: item.comptrastType
               }));

            return {
               alarmId: item.alarmId,
               vehicleId: item.vehicleId,
               groupId: item.groupId,
               plate: item.vCph,
               groupName: item.groupName,
               terminalNo: item.vSim,
               phone: item.phone,
               GPhone: item.GPhone,
               speed: item.speed,
               similar: item.similar,
               result: item.result,
               threshold: item.threshold,
               comptrastType: item.comptrastType,
               lon: item.lon,
               lat: item.lat,
               alarmStartTime: item.alarmStartTime,
               alarmEndTime: item.alarmEndTime,
               alarmTypes: [],
               adasFileList,
               alarmStartTimeStr,
               alarmEndTimeStr,
               eventType,
               // adasLevel
            };
         });
         return { flag: res.flag, alarmList, time };
      });


/**
 * 获取链路状态
 * @param {*} params.SuperiorName  上级平台名称
 */
export const findforwardlinktype = (params) => axios.get('platformDataExchange/findforwardlinktype.do', { params });

/**
 * 服务器状态监控
 */
export const getServerStauts = (params) => axios.get('server/monitor.do', { params });

/**
 * 获取位置数据
 * @param {*} params 
 */
export const getPosistionStatus = (params) => axios.get('platformDataExchange/getPositionSum.do', { params });

/**
 * 重置位置数据
 */
export const resetPositionStatus = (params) => axios.get('platformDataExchange/setPositionSum.do', { params });

/**
 * 保存指令调试
 * @param {*} params 
 * @returns 
 */
export const setSaveSetting = (params) => axios.post('peripheral/saveSetting.do', params);